.flex-group {
    margin: $marginMedium 0;
    display: flex;

    & > *:not(:nth-child(1)) {
        margin-left: $marginMedium;
    }

    & > * {
        flex: 1;
    }
}

.form-group {
    display: flex;
    flex-direction: column;

    label {
        white-space: nowrap;
        display: block;
        color: $grey;
        margin: $marginTiny 0;
    }
    input {
        display: block;
        margin: $marginTiny 0;
    }
    small.desc {
        display: block;
        margin: $marginTiny 0;
    }

    .form-checkbox {
        display: flex;
        margin-top: 1.1em;
        
        label {
            margin-left: $marginSmall;
        }
    }
}
