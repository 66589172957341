$primaryColour: rgb(255, 77, 86); // #ff4d56;
$grey: #7f7f7f;
$lightGrey: #fcfcfc;
$mediumGrey: #e9e9e9;
$blue: #0074ff;
$red: #ff2222;
$white: #fff;

$h1Size: 28px;
$h2Size: 22px;
$h3Size: 16px;

$borderColour: $mediumGrey;
$borderThin: 1px;
$borderMedium: 3px;
$borderRadius: 4px;
$border: $borderThin solid $borderColour;

$curveRadius: 4px;

$fontFamily: 'CircularMedium', sans-serif;
$fontWeight: 400;
$fontFamilyHeavy: 'CircularBold', sans-serif;
$fontWeightHeavy: 700;
$fontSizeSmall: 8px;
$fontSizeMedium: 14px;
$fontSizeLarge: 20px;
$fontSizeXLarge: 32px;

$paddingSmall: 8px;
$paddingMedium: 16px;
$paddingLarge: 24px;
$marginTiny: 4px;
$marginSmall: 8px;
$marginMedium: 16px;
$marginLarge: 24px;

$headerBackground: $lightGrey;
$headerPadding: $paddingSmall;
$headerHeight: 60px;

$buttonAltBorderColour: $primaryColour;
$buttonAltColour: $white;
$buttonAltTextColour: $primaryColour;
$buttonAltHoverColour: $buttonAltTextColour;
$buttonAltHoverTextColour: $buttonAltColour;
$buttonAltDisabledBorderColour: $grey;
$buttonAltDisabledTextColour: $grey;

$buttonTextColour: $white;
$buttonBackgroundColour: $primaryColour;
$buttonBorderColour: $primaryColour;
$buttonBorderWidth: $borderMedium;

$buttonDangerColour: $red;
$buttonDangerBorderColour: $red;
$buttonDangerTextColour: $white;

$buttonHoverBackgroundColour: $buttonTextColour;
$buttonHoverBorderColour: $buttonBorderColour;
$buttonHoverTextColour: $buttonBackgroundColour;

$buttonHorizontalPadding: $paddingSmall;
$buttonVerticalPadding: $paddingSmall;

$buttonDisabledTextColour: $lightGrey;
$buttonDisabledBackgroundColour: $grey;
$buttonDisabledBorderColour: $grey;

$profileImageSizeSmall: $headerHeight - (2 * $headerPadding);
$profileBorderRadius: 100%;
$profileLinkColor: #000;

$navWidth: 200px;

$mainBackground: #fcfcfc;

$tableTdColor: $grey;
$tableBorder: $border;
$tableBorderRadius: $borderRadius;

/*
$bgColor: #fcfcfc;
$blue: #0074ff;
$gray: #7f7f7f;
$tableGray: rgba(#000, .5);
*/
