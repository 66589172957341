/*
* {
    @include font-normal;
}
*/

body {
}

h1 {
    font-size: $h1Size;
}

h2 {
    font-size: $h2Size;
}

h3 {
    font-size: $h3Size;
}

pre {
    font-size: 80%;
}

a {
    color: $primaryColour;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.quiet {
        color: inherit;
    }
}

/*
.btn {
    @include button-styles;
}
*/
